import React from 'react';
import './assets/style.scss';
import { Link } from '@reach/router';
import logo from '../../../shop/assets/img/logo.png';

function Logo({ displayLogo }) {
  return (
    displayLogo && (
      <Link to="/" className="logo" id="main-logo">
        <img src={logo} alt="" />
      </Link>
    )
  );
}

export default Logo;
